import _ from "lodash";

class ProductListForm extends HTMLElement {
  constructor() {
    super();

    this.form = this.querySelector("form");
    this.form.querySelector("[name=id]").disabled = false;
    this.form.addEventListener("submit", this.onSubmitHandler.bind(this));
    this.cart =
      document.querySelector("cart-notification") ||
      document.querySelector("cart-drawer");
    this.submitButton = this.querySelector('[type="submit"]');
    if (document.querySelector("cart-drawer"))
      this.submitButton.setAttribute("aria-haspopup", "dialog");

    if (this.dataset.variants) {
      this.variants = JSON.parse(this.dataset.variants);
    }

    this.form.addEventListener("change", (event) => {
      if (event.target.closest("[data-option]")) {
        this.onOptionChange(event);
      }
    });
  }

  onOptionChange(event) {
    const formEl = this.form;
    const variantString =
      this.dataset.variantType == "dropdown"
        ? "select.variant-select"
        : 'input[type="radio"]:checked';

    const option1 = formEl.querySelector(`[data-option="1"] ${variantString}`);
    const option2 = formEl.querySelector(`[data-option="2"] ${variantString}`);
    const option3 = formEl.querySelector(`[data-option="3"] ${variantString}`);

    const matchingVariant = _.find(this.variants, {
      option1: option1?.value || null,
      option2: option2?.value || null,
      option3: option3?.value || null,
    });

    this.querySelector('input[name="id"]').value = matchingVariant.id;

    this.dispatchEvent(
      new CustomEvent("change-variant", {
        detail: { id: matchingVariant.id },
        bubbles: true,
      })
    );
  }

  onSubmitHandler(evt) {
    evt.preventDefault();
    if (this.submitButton.getAttribute('aria-disabled') === 'true') return;

    this.handleErrorMessage();

    this.submitButton.setAttribute('aria-disabled', true);
    this.submitButton.classList.add('loading');
    this.querySelector('.loading-overlay__spinner').classList.remove('hidden');

    const config = fetchConfig('javascript');
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    delete config.headers['Content-Type'];

    const formData = new FormData(this.form);
    if (this.cart) {
      formData.append('sections', this.cart.getSectionsToRender().map((section) => section.id));
      formData.append('sections_url', window.location.pathname);
      this.cart.setActiveElement(document.activeElement);
    }
    config.body = formData;

    fetch(`${routes.cart_add_url}`, config)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          this.handleErrorMessage(response.description);

          const soldOutMessage = this.submitButton.querySelector('.sold-out-message');
          if (!soldOutMessage) return;
          this.submitButton.setAttribute('aria-disabled', true);
          this.submitButton.querySelector('span').classList.add('hidden');
          soldOutMessage.classList.remove('hidden');
          this.error = true;
          return;
        } else if (!this.cart) {
          window.location = window.routes.cart_url;
          return;
        }

        this.error = false;
        const quickAddModal = this.closest('quick-add-modal');
        if (quickAddModal) {
          document.body.addEventListener('modalClosed', () => {
            setTimeout(() => { this.cart.renderContents(response) });
          }, { once: true });
          quickAddModal.hide(true);
        } else {
          this.cart.renderContents(response);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        this.submitButton.classList.remove('loading');
        if (this.cart && this.cart.classList.contains('is-empty')) this.cart.classList.remove('is-empty');
        if (!this.error) this.submitButton.removeAttribute('aria-disabled');
        this.querySelector('.loading-overlay__spinner').classList.add('hidden');
      });
  }

  handleErrorMessage(errorMessage = false) {
    this.errorMessageWrapper = this.errorMessageWrapper || this.querySelector('.product-form__error-message-wrapper');
    if (!this.errorMessageWrapper) return;
    this.errorMessage = this.errorMessage || this.errorMessageWrapper.querySelector('.product-form__error-message');

    this.errorMessageWrapper.toggleAttribute('hidden', !errorMessage);

    if (errorMessage) {
      this.errorMessage.textContent = errorMessage;
    }
  }
}

customElements.define("product-list-form", ProductListForm);
